.c-tasks-list-task {
  $this: &;

  @at-root
  {
    #{$this}:hover {
      #{$this}__drag {
        display: block;
      }
      #{$this}__circle-container{
        display: block;
      }
    }

    #{$this}--unseen {
      background-color: var(--theme-color-l95);

      #{$this}__title,
      #{$this}__subtitle {
        font-weight: 500;
      }

    }

    #{$this}__left-group {
      display: flex;
      align-items: center;
    }

    #{$this}__dragTask {
      transform: rotate(3deg);
      cursor: grab;
    }

    #{$this}__dragTaskInList {
      cursor: grab;
      background-color: #f5f7fa;
      opacity: 1;
    }

    #{$this}__dragGhostTaskList {
      visibility: hidden;
    }

    #{$this}__dragGhostTask {
      visibility: hidden;
    }

    #{$this}__dragGhostTaskSafari {
      visibility: visible;
      background-color: #6e6969;
      border: 1px dashed grey;
    }

    #{$this}__infos-group {
      display: flex;
      flex-direction: column;
      margin-left: $size-xx-small;
    }

    #{$this}__infos-group--list {
      margin-left: 0px;
    }

    #{$this}__title-group {
      display: flex;
      align-items: center;
      word-break: break-word;
      width: 100%;
    }

    #{$this}__title {
      font-size: 17px;
      color: $text-color;
      font-weight: normal;
      margin: 0 0 5px 0;

      &--check {
        text-decoration: line-through;
        color: $color-dark-light;
      }

      @media (min-width: $screen-sm-min) {
        font-size: 15px;
        margin: 0;

      }
    }

    #{$this}__subtitle {
      font-size: 13px;
      margin: 0;
      color: $text-color;
      font-weight: normal;
    }

    #{$this}__dropdown {
      margin-left: $size-xxx-small;
    }

    #{$this}__infos {
      display: flex;
      align-items: center;
      margin-top: $size-xxx-small;
    }

    #{$this}__iconAssignation {
      color: green;
      height: 25px;
      width: 25px;
      cursor: pointer;
    }

    #{$this}__subtitle {
      font-size: 15px;
      color: $color-default;
      margin: 0;
      margin-right: $size-xx-small;
      margin-bottom:$size-xx-small;
      font-weight: normal;
      max-width: 280px;

      @media (min-width: $screen-sm-min) {
        font-size: 13px;
        max-width: unset;
      }
    }

    #{$this}__circle-container {
      min-width: 24px;
      @media (min-width: $screen-md-min) {
        display: none;
      }

      &--checked {
        #{$this}__circle {
          display: none;
        }

        #{$this}__check {
          display: block;
        }
      }
    }



    #{$this}__circle {
      height: 15px;
      width: 15px;
      border: 1.7px solid #6a69ab;
      color: var(--theme-color);
      border-radius: 8%;
    }

    #{$this}__check {
      width: 15px;
      height: 15px;
      color: var(--theme-color);
      display: none;
    }

    #{$this}__user-group--container {
      float: right;
    }

    #{$this}__user-group {
      display: flex;
      align-items: center;

      &--relative {
        position: relative;
      }

     .c-tasks-sub-header__users-more {
       width: 25px;
         height: 25px;
     }
    }

    #{$this}__user {

      //@media (max-width: $screen-sm-min) {
      //  height: 32px;
      //  width: 32px;
      //}

      margin-left: -5px;
      height: 25px;
      width: 25px;
      font-size: 13px;

    }


    #{$this}__user--mini {
      margin-left: -5px;
      height: 20px;
      width: 20px;
      font-size: 13px;
    }

    #{$this}__user--adminIcon {
      background-image: url('/assets/icons/chevron.png');
      width: 9px;
      height: 9px;
      background-size: contain;
      position: absolute;
      bottom: 0px;
      right: 15px;
    }

    #{$this}__user--containerAdminIcon {
      position: relative;
    }

    #{$this}__team--icone {
        cursor: pointer;
    }

    #{$this}__drag {
      margin-right: $size-xxx-small;
      width: 15px;
      height: 15px;
      color: $color-default;
      flex-shrink: 0;

      @media (min-width: $screen-md-min) {
        display: none;
      }
    }

    #{$this}__assignation {
      width: fit-content;
      margin-left: auto;
      margin-right: auto;
      align-items: center;

      & p {
        margin: 0;
        margin-right: $size-xxx-small;
        color: var(--theme-color);
      }
    }

    #{$this}__assignation-actions {
      text-align: center;
      & a {
        font-size: 15px;
        color: var(--theme-color);
        text-decoration: underline;
      }
    }

    #{$this}__icon {
      margin-right: 10px;
      font-size: 16px;
      display: none;

      #{$this}:not(.is-members-popup-opened):hover & {
        @media (min-width: $screen-sm-min) {
          display: block;
        }
      }
    }

    #{$this}__icon--mobile {
      margin-right: 10px;
      font-size: 16px;
      display: block;
      cursor: grab;
      color: #434a54;
    }

    #{$this}__container--assignation {
      display: inline-flex;
    }

    #{$this}__clock {
      top: 10px;
      background-color: white;
      border-radius: 50%;
      left: 6px;
      font-size: 12px;
      z-index: 1;
      color: #434a54;
      margin-left: -10px;
      display: inline-block
    }

    #{$this}__clockkanban {
      top: 17px;
      background-color: white;
      border-radius: 50%;
      left: 4px;
      font-size: 12px;
      z-index: 1;
      color: #434a54;
      margin-left: -10px;
      display: inline-block;
      height: 12px
    }

    #{$this}__clockreload {
      top: 11px;
      background-color: white;
      border-radius: 50%;
      left: 8px;
      font-size: 12px;
      z-index: 1;
      color: #434a54;
      margin-left: -10px;
      display: inline-block
    }

    #{$this}__clocklistmembers:before {
      position: absolute;
      top: 26px;
      background-color: white;
      border-radius: 999px;
      left: 5px;
      font-size: 1.6em;
      z-index: 1;
      color: #434a54;
      margin-left: -10px;
      display: inline-block
    }

    #{$this}__star {
      top: 11px;
      display: inline-block;
      left: 9px;
      z-index: 1;
      color: #f99d0f;
      margin-left: -10px;
    }

    #{$this}__starkanban {
      top: 17px;
      left: 5px;
      z-index: 1;
      color: #f99d0f;
      margin-left: -10px;
      display: inline-block;
      height: 12px;
    }

    #{$this}__starkanban-modal {
      top: 25px;
      left: 17px;
      z-index: 1;
      color: #f99d0f;
      margin-left: -21px;
      display: inline-block;
      font-size: 20px;
    }

    #{$this}__clocklistmembers-modal {
      top: 25px;
      left: 11px;
      z-index: 1;
      margin-left: -16px;
      display: inline-block;
      font-size: 19px;
      background-color: white;
      border-radius: 50%;
    }

    #{$this}__starreload {
      top: 11px;
      display: inline-block;
      left: 8px;
      z-index: 1;
      color: #f99d0f;
      margin-left: -10px;
    }

    #{$this}__starlistmembers::before {
      position: absolute;
      top: 26px;
      display: inline-block;
      left: 5px;
      z-index: 1;
      font-size: 1.6em;
      color: #f99d0f;
      margin-left: -10px;
    }
  }
}
