.c-tasks-dashboard {
  $this: &;

  @at-root {

    #{$this}__wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      margin: 20px 10px 0 10px
    }

    #{$this}__row--container {
      border-bottom: 1px solid lightgray !important;
    }

    #{$this}__buttonToggleView {
      position: absolute !important;
      left: 45px;
    }

    #{$this}__flottingButton {
      position: absolute !important;
      left: 45px;
    }

    #{$this}__row {
      cursor: pointer;
      display: flow-root;
      border-bottom: none;
      @media (max-width: $screen-sm-min) {
        height:auto;
      }
    }

    #{$this}__containerFavorites {
      display: inline-block;
    }

    #{$this}__containerFlagAndPin {
      display: inline-flex;
      position: absolute;
      right: 2px;
      top: 7px;
      @media (max-width: $screen-sm-min) {
        right: 0px;
        width: 50px;
      }
    }

    #{$this}__tasklistContainer {
      width: 193px;
      height: 96px;
      border-radius: 3px;
      display: inline-flex;
      margin: 0px 7px 30px 7px;
      cursor: pointer;
      background: linear-gradient(to bottom right, #606161, #f3f0f0);
    }

    #{$this}__foldersContainer {
      background: #e9e9e9 !important;
    }

    #{$this}__foldersContainer:hover {
      background: darken(#e9e9e9, 5%) !important;
      transition: background 0.3s ease;
    }

    #{$this}__avatar{
      display: inline-flex;
      margin-left: 5px;
      position: absolute;
      right: 0;
      margin-top: auto;
      margin-bottom: auto;
      top: 52px;
    }


    #{$this}__assignated-grid .k-table-tbody > .k-table-row:not(.k-detail-row):hover {
      background: #F5F5F5 !important;
    }

    #{$this}__assignated-grid .k-grid .k-table-td:first-child {
     background-color: red !important;
    }

    #{$this}__assignated-grid .k-table-alt-row  {
      background-color: transparent !important;
    }

    #{$this}__assignated-grid .k-grid-content.k-auto-scrollable {
        @include custom-scrollbar();
        height: calc(#{vh(100)} - #{$header-height} - 100px)  !important;
    }

    #{$this}__assignated-grid .k-grid-content.k-auto-scrollable.c-tasks-dashboard__customHeightContainerMenu--sidebar  {
        @include custom-scrollbar();
        height: calc(#{vh(100)} - #{$header-height} - 111px)  !important;
    }

  #{$this}__assignated-grid .k-grid-content.k-auto-scrollable.c-tasks-dashboard__customHeightContainerMenu--aside  {
        @include custom-scrollbar();
        height: calc(#{vh(100)} - #{$header-height} - 71px)  !important;
    }

    #{$this}__assignated-grid .k-grid-header {
      display: none;
    }

    #{$this}__assignated-grid .k-grid .k-table-th, .k-grid td, .k-grid .k-table-td {
      position: relative;
    }

    #{$this}__assignated-grid .k-grid-header th.k-header {
      color: #434a54;
      font-weight: 500;
      font-size: 21px;
    }

    #{$this}__tooltip-container {
      background-color: white !important;
    }

    #{$this}__iconStarEmpty--title {
      display: inline-flex;
      font-size: 2em;
      color: #434a54;
      vertical-align: bottom;
    }

    #{$this}__iconNoData {
      font-size: 1.5em;
      color: #434a54;
      vertical-align: sub;
    }

    #{$this}__iconFolderOpen {
      display: inline-flex;
      font-size: 1.4em;
      color: #434a54;
      margin-right: 6px;
    }

    #{$this}__iconList {
      display: inline-flex;
      font-size: 1.4em;
      color: #434a54;
      margin-right: 6px;
    }

    #{$this}__iconTeam {
      position: absolute;
      bottom: 55px;
      color: white;
      font-size: 23px;
    }

    #{$this}__iconFolderOpen--folders {
      display: inline-flex;
      font-size: 3.4em;
      color: #434a54;
      margin-right: 6px;
    }

    #{$this}__checkBox--section {
      top: 50%;
      margin-bottom: auto;
      margin-top: auto;
      display: none;
      align-items: center;
      justify-content:center;
      position: relative;

      @media (min-width: $screen-sm-min) {
        .k-table-row:hover & {
          display: block;
        }
      }
      @media (max-width: $screen-sm-min) {
        display: block;
        margin-right: 5px;
      }
    }

    #{$this}__item-dropdown {
        text-align: center;
        position: absolute;
        z-index: 2;
        cursor: pointer;
        right: 10px;
        top: 38px;
    }


      #{$this}__checkBox--container{
      position: relative;
      height: 25px;
    }

    #{$this}__iconFlagContainer{
      width: 20px;
      height: 20px;
      margin-right: 3px;
      position: relative;
      left: -6px;

      @media (min-width: $screen-sm-min) {
        &:hover {
          background: lightgrey;
          border-radius: 3px;
        }
      }

      @media (max-width: $screen-sm-min) {
        display: none;
        &--display {
          display: block;
          left: -11px;
        }
      }
    }

    #{$this}__iconFlag {
      font-size: 23px;
      height: 22px;
      cursor: pointer;
      fill: transparent;
      stroke: #44546f;
      vertical-align: sub;
      stroke-width: 1px;
      position: relative;
      display: none;

      &--display{
        fill: #a5302c;
        stroke: #a5302c;
        display: block;
      }
      @media (min-width: $screen-sm-min) {
        height: 17px;
        width: 17px;
      }
    }

    #{$this}__iconSidebar--container{
      margin-left: auto;
      margin-right: auto;
    }

    #{$this}__iconSidebar {
      font-size: 23px;
      height: 22px;
    }

    #{$this}__iconArchiveContainer{
      width: 20px;
      height: 3px;

      @media (min-width: $screen-sm-min) {
        &:hover {
          background: #FDF2F3;
          .c-tasks-dashboard__iconArchive{
            color: #ED6056;
          }
        }
      }
    }

    #{$this}__iconStarContainer{
      width: 30px;
      height: 100%;
      position: absolute;
      right: 0px;
      top: 0px;
      padding-left: 6px;
    }

    #{$this}__iconEnvelopContainer{
      width: 30px;
      height: 100%;
      position: absolute;
      right: 0px;
      top: 0px;
      padding-left: 6px;
      background: #FDF2F3;
      @media (min-width: $screen-sm-min) {
        &:hover {
          .c-tasks-dashboard__iconAssignation{
            color: #ED6056;
          }
        }
      }
      @media (max-width: $screen-sm-min) {
        left: 18px;
        background: transparent;
      }
    }

    #{$this}__iconEnvelopContainer::before{
      content: '';
      background: #a5302c;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 40%;
      left: 16px;
      z-index: 8;
    }

    #{$this}__iconClockContainer{
      width: 30px;
      height: 100%;
      position: absolute;
      right: 0px;
      top: 0px;
      padding-left: 6px;
      background: #FDF2F3;
      @media (max-width: $screen-sm-min) {
        left: 18px;
        background: transparent;
      }
    }

    #{$this}__iconClockContainer::before{
      background: #a5302c;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 40%;
      left: 16px;
      z-index: 8;
    }

    #{$this}__iconArchive {
      font-size: 21px;
      height: 21px;
      cursor: pointer;
      color: #44546f;
      vertical-align: sub;
      stroke-width: 1px;
      display: initial;
      top: 40%;
      position: relative;

      @media (max-width: $screen-sm-min) {
        display: none;
      }

      @media (min-width: $screen-sm-min) {
        height: 17px;
        width: 17px;
        display: none;
        &--display{
          display: block;
        }
      }
    }

    #{$this}__iconStarFollowed {
      font-size: 21px;
      height: 21px;
      cursor: pointer;
      color: #44546f;
      vertical-align: sub;
      stroke-width: 1px;
      display: initial;
      top: 40%;
      position: relative;

      @media (max-width: $screen-sm-min) {
        display: none;
      }

      @media (min-width: $screen-sm-min) {
        height: 17px;
        width: 17px;
        display: none;
        &--display{
          display: block;
        }
      }
    }

    #{$this}__iconAssignation {
      font-size: 21px;
      height: 21px;
      cursor: pointer;
      color: #44546f;
      vertical-align: sub;
      stroke-width: 1px;
      display: initial;
      top: 40%;
      position: relative;

      @media (min-width: $screen-sm-min) {
        height: 17px;
        width: 17px;
      }
    }

    #{$this}__titleAssignationPopup {
      margin: 0;
    }

    #{$this}__buttonDeleteAssignationPopup {
      display: inline-flex;
      margin: auto;
    }

    #{$this}__iconPushpinContainer{
      width: 20px;
      height: 20px;
      margin-right: 10px;
      @media (min-width: $screen-sm-max) {
        &:hover {
          background: lightgrey;
          border-radius: 3px;
        }
      }
      @media (max-width: $screen-sm-min) {
        display: none;
        &--display {
          display: block;
          right: 0;
          position: absolute;
        }
      }
    }

    #{$this}__iconPushpin {
      font-size: 21px;
      height: 21px;
      cursor: pointer;
      vertical-align: sub;
      stroke-width: 1px;
      position: relative;
      display: none;
      fill: transparent;
      stroke: #44546f;
      margin-left: 1px;

      &--display{
        fill:  #44546f;
        stroke: #44546f;
        display: block;
      }
      @media (min-width: $screen-sm-min) {
        height: 17px;
        width: 17px;
      }
    }

    #{$this}__iconUsers {
      font-size: 21px;
      height: 21px;
      cursor: pointer;
      color: #44546f;
      vertical-align: sub;
      stroke-width: 1px;
      position: relative;
      top: 1px;
      display: initial;

      @media (min-width: $screen-sm-min) {
        height: 16px;
        width: 14px;
        display: none;
      }
    }

    @media (min-width: $screen-sm-min) {
      #{$this}__assignated-grid .k-table-row:hover #{$this}__titleTask {
        -webkit-line-clamp: 1;
      }
    }

    @media (min-width: $screen-sm-min) {
      .k-table-row:hover #{$this}__iconUsers {
        display: initial;
      }
    }

    @media (min-width: $screen-sm-min) {
      .k-table-row:hover #{$this}__iconFlag {
        display: initial;
      }
    }

    @media (min-width: $screen-sm-min) {
      .k-table-row:hover #{$this}__iconPushpin {
        display: initial;
      }
    }

    @media (min-width: $screen-sm-min) {
      .k-table-row:hover #{$this}__iconArchive {
        display: initial;
      }
    }

    @media (min-width: $screen-sm-min) {
      .k-table-row:hover #{$this}__iconStarFollowed {
        display: initial;
      }
    }

    #{$this}__checkBox {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      font-size: 26px;
      display: block;
      z-index: 9;
      height: 26px;
      @media (min-width: $screen-sm-min) {
        .k-table-row:hover & {
          display: block;
          margin-right: 20px;
          margin-top: 50%;
          margin-bottom: 50%;
        }
      }

      @media (min-width: $screen-sm-min) {
        display: none;
      }

      @media (max-width: $screen-sm-min) {
        margin-top: 100%;
      }

      &--finished{
        stroke: var(--theme-color);
        fill: var(--theme-color-darken20);
        display:block;
        background-color: var(--theme-color);
        border-radius: 50%;
        @media (min-width: $screen-sm-min) {
          margin-right: 20px;
        }
      }
    }

    #{$this}__check {
      font-size: 11px;
      z-index: 9999;
      stroke-width: 5px;
      position: relative;
      display: none;
      left: 7px;
      top: -54px;

      #{$this}__checkBox--container:hover & {
        @media (min-width: $screen-sm-min) {
          display: block;
        }
      }
    }

    #{$this}__check--finished {
      font-size: 11px;
      stroke-width: 5px;
      z-index: 10;
      position: absolute;
      display: block;
      left: 7px;
      top: -4px;
      color: white;

      #{$this}__checkBox--container:hover & {
        @media (min-width: $screen-sm-min) {
          display: none;
        }
      }
    }



    #{$this}__popupAssignation {
      height: 100%;
      display: inline-grid;
      align-items: center;
      padding: 9px;
      color: #434a54;
      font-size: 15px;
    }

    #{$this}__containerButtonDeleteAssignation {
      display: inline-flex;
      margin: auto;
    }

    #{$this}__buttonDeleteAssignation {
      margin-top: 5px;
      text-align: center;
    }

    #{$this}__displayNbr,
    #{$this}__iconTags {
      color: #44546f;
      cursor: pointer;
      position: relative;
      margin-left: 10px;
      display: initial;
    }

    #{$this}__displayNbr {
      font-size: 19px;
      padding-top: 0;
      margin-left: 0
    }

    #{$this}__iconTags{
      font-size: 23px;
      vertical-align: bottom;
      stroke-width: 1px;
    }

    @media (min-width: $screen-sm-min) {
      #{$this}__iconTags,
      #{$this}__iconArchive,
      #{$this}__displayNbr{
        font-size:16px;
        display: none;
      }

      .k-table-row:hover #{$this}__iconTags,
      .k-table-row:hover #{$this}__displayNbr,
      .k-table-row:hover #{$this}__iconArchive{
        display: initial;
      }
    }

    /* La règle pour le survol de #{$this}__displayNbr n'est pas nécessaire car cet élément ne semble pas avoir de lien avec les éléments ".k-table-row" */

    //#{$this}__iconAssignation:before {
    //  content: '';
    //  background-color: var(--theme-color);
    //  display: block;
    //  width: 35px;
    //  height: calc(#{vh(100)} - #{$header-height} - 140px)  !important;
    //  position: absolute;
    //  left: 0;
    //  top: 0;
    //}
    #{$this}__iconUser {
      margin-left: -6px;
      height: 30px;
      width: 30px;
      font-size: 13px;
      display: inline-flex;
    }

    #{$this}__tag {
      cursor: pointer;
      border-radius: 3px;
      box-sizing: border-box;
      display: block;
      float: left;
      height: 25px;
      line-height: 20px;
      margin: 4px 10px 0 0;
      min-width: 40px;
      padding: 0 10px;
      width: auto;
      font-size: 13px;
      color: black;
      font-weight: normal;
      display: flex;
      align-items: center;
    }

    #{$this}__tagCircle {
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }

    #{$this}__tagName {
      color: #434a54;
      font-size: 15px;
      font-weight: 400;
    }

    #{$this}__iconUserMore {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #e6e9ed;
      text-align: center;
      padding: 0 !important;
      font-size: 13px;
      //font-family: Rubik', sans-serif;
      font-family: 'Apercu', sans-serif;
      color: #656565;
      margin-right: 0 !important;
      margin-left: 10px;
      border: white 1px solid;
    }

    #{$this}__iconClock {
      top: 12px;
      background-color: white;
      border-radius: 50%;
      left: 6px;
      font-size: 17px;
      z-index: 1;
      color: #434a54;
      margin-left: -23px;
      display: inline-block;
    }

    #{$this}__iconStarAssignatedTask {
      top: 13px;
      display: inline-block;
      left: 8px;
      z-index: 1;
      color: #f99d0f;
      margin-left: -22px;
      font-size: 19px;
    }

    #{$this}__infos-group {
      display: flex;
      flex-direction: column;
      margin-left: 0px;
      width: 100%;
      &--mobileWithAssignation{
        margin-left: 17%;
      }
    }

    #{$this}__infos {
      display: inline-flex;
      right: 10px;
      top: 52px;
      font-size: 13px;

      @media (max-width: $screen-sm-min) {
        top: 62px;
      }
    }

    #{$this}__title {
      color: #434a54;
      font-weight: 500;
      font-size: 17px;
      margin: 0 5px;
      display: inline-flex;
    }

    #{$this}__subtitle--container {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 15px;
      color: $color-default;
      margin: 0;
      margin-right: $size-xx-small;
      font-weight: normal;


      @media (min-width: $screen-sm-min) {
        font-size: 13px;
      }

    }

    #{$this}__subtitle {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: bold;
      width: 70%;
      @media (min-width: 768px) {
        .k-table-row:hover & {
          width: 60%;
        }
      }
      &--finish{
        width: 62%;
      }
    }

    #{$this}__subtitle--nameAssingationCreator {
      white-space: nowrap;
      overflow: hidden;
      display: inline-flex;
      text-overflow: ellipsis;
      font-size: 15px;
      color: #5e6c84;
      margin: 0;
      margin-right: $size-xx-small;
      font-weight: 500;

      .k-table-row:hover & {

      }


      @media (min-width: $screen-sm-min) {
        font-size: 13px;
        max-width: unset;
      }

      @media (min-width: 768px) {

      }
    }

    #{$this}__title--noDataDashboard {
      font-weight: 300;
      font-size: 15px;
      margin-left: 30px;
    }

    #{$this}__title--noDataAssignatedTasks {
      font-weight: 300;
      font-size: 15px;
      margin-left: 30px;
    }

    #{$this}__title-group {
      display: flex;
      align-items: center;
      word-break: break-word;
      width: 100%;
      margin-bottom: 5px;
      margin-top: 5px;

      &--finish{
        width: 90%;
      }
    }

    #{$this}__titleTasklist {
      color: white;
      font-weight: 500;
      font-size: 17px;
      margin: 5px;
      display: inline-flex;
      text-align: left;
      width: 70%;
      text-shadow: 0.5px 0.5px #434a54;
    }

    #{$this}__titleFolder {
      color: #434a54 !important;
      text-shadow: none !important;
    }

    #{$this}__titleTask {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      font-size: 17px;
      font-weight: 500;
      margin: 0;

    }

    #{$this}__header {
      margin: 10px;
    }

    #{$this}__header--assignatedTasks {
      margin: 20px;
      text-align: center;
    }

    #{$this}__containerIconTasklist {
      position: relative;
      top: 65px;
      left: 15px;
      height: fit-content;
    }

    #{$this}__iconStarEmpty {
      display: inline-block;
      font-size: 26px;
      color: white;
      cursor: pointer;
      position: absolute;

      @media (min-width: 768px) {
        font-size: 1.4em;
      }
    }

    #{$this}__iconStar {
      font-size: 26px;
      color: #efd163;
      cursor: pointer;

      &:hover {
        display: none;
      }

      @media (min-width: 768px) {
        font-size: 1.4em;
      }
    }

    #{$this}__iconStarEmpty--tasklistNotFavorite {
      display: inline-block;
      font-size: 26px;
      color: white;
      cursor: pointer;
      position: absolute;

      &:hover {
        display: none;
      }

      @media (min-width: 768px) {
        font-size: 1.4em;
      }
    }

    #{$this}__iconStar--tasklistNotFavorite {
      font-size: 26px;
      color: #efd163;
      cursor: pointer;
      width: 30px;
      height: 30px;
      opacity: 0;

      &:hover {
        opacity: 1;
      }

      @media (min-width: 768px) {
        font-size: 1.4em;
      }
    }

    #{$this}__folders {
      margin: 30px 10px 30px 30px;
    }

    #{$this}__cards .cards-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    #{$this}__cards .k-card-header {
      color: white;
      border: none;
      text-shadow: 0.5px 0.5px #434a54;
      font-weight: 500;
      font-size: 17px;
      width: 75%;
    }

    #{$this}__cards .k-card {
      width: 45vw;
      height: 96px;
      display: inline-flex;
      border: none;
      background: linear-gradient(to top left, rgb(139,198,236), rgb(55,65,145));
      cursor: pointer;
      border-radius: 3px;

      &:hover {
        filter: brightness(0.9);
      }

      &:active {
        filter: brightness(0.8);
      }

      @media (min-width: 768px) {
        height: 96px;
        width: 193px;
      }
    }


    #{$this}__cards .k-card *, .k-card *::before, .k-card *::after {
      box-sizing: border-box;
      cursor: pointer;
    }

    #{$this}__cards .k-card-action > .k-button {
      width: 100%;
    }

    #{$this}__cards .k-card-actions-vertical {
      flex-flow: column nowrap;
    }

    #{$this}__cards .k-card-actions-vertical {
      flex-flow: column nowrap;
      width: 25%;
    }

    #{$this}__cards .k-icon {
      color: #efd163;
    }

  }
}